import isAfter from 'date-fns/isAfter';

import { TSubscription, TValidationResult } from 'api/userFilters/types';

export const hasActivePromotion = (subscription?: TSubscription | TValidationResult | null): boolean => {
  if (!subscription) return false;

  let promotionEndDate;

  if ('subscriptionPromotionEndDate' in subscription) {
    promotionEndDate = subscription.subscriptionPromotionEndDate;
  }

  if ('promotionSubscriptionEndDate' in subscription) {
    promotionEndDate = subscription.promotionSubscriptionEndDate;
  }

  if (promotionEndDate) {
    const endDate = new Date(promotionEndDate);
    const hasPromotionSubscription = isAfter(endDate, new Date());

    return hasPromotionSubscription;
  }

  return false;
};
