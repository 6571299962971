import type { Suggestions } from '@components/quick-firm-search/dist/types';
import { makeAutoObservable } from 'mobx';

import { getFirmsCompletionList } from 'api/firms';
import { getGeoCompletionList } from 'api/geo';
import { geoTypes } from 'constants/routeParams';
import { TUikitSupportedLocale } from 'localization/types';
import { RootStore } from 'store/RootStore';
import { SelectField } from 'store/fileds/SelectField';
import { SuggestionField } from 'store/fileds/Suggestion';
import { LocalityType } from 'types/cargosApp/Geo';

export interface IFirm {
  alias_id: string;
}

class Firms {
  root;
  ratingType;
  isExclusiveMode;
  firmGeo;
  firmName;
  selectedLists: Array<string>;

  constructor(root: RootStore) {
    this.root = root;
    this.ratingType = new SelectField(this.root.options.getRatingTypes);
    this.isExclusiveMode = new SelectField(this.root.options.getExclusiveTypes);
    this.firmGeo = new SuggestionField<LocalityType>({
      rootStore: root,
      onFetch: this.fetchGeoCompletionList,
    });
    this.firmName = new SuggestionField<IFirm, Suggestions>({
      rootStore: root,
      onFetch: this.fetchFirmsCompletionList,
    });
    this.selectedLists = [];

    makeAutoObservable(this);
  }

  get isSelectedListsDefault() {
    return this.selectedLists.length === 0;
  }

  get isDefault() {
    return (
      this.isSelectedListsDefault && this.ratingType.isDefault && this.firmGeo.isDefault && this.firmName.isDefault
    );
  }

  get requestData(): TFirms {
    const lists = this.isExclusiveMode.data
      ? { firmListsExclusive: this.selectedLists }
      : { firmListsInclusive: this.selectedLists };

    return {
      atiId: this.firmName.suggestion?.alias_id,
      firmRating: this.ratingType.data || null,
      ...(this.firmName.value && { firmName: this.firmName.value }),
      firmGeo: { id: this.firmGeo.suggestion?.id, type: this.firmGeo.suggestion?.type },
      ...lists,
    };
  }

  setSelectedList = (value: string[]) => {
    this.selectedLists = value;
  };

  selectList = (value: string) => {
    this.setSelectedList([value]);
  };

  resetList = () => {
    this.setSelectedList([]);
  };

  toggleList = (value: string) => {
    if (this.selectedLists.includes(value)) {
      this.resetList();
    } else {
      this.selectList(value);
    }
  };

  fetchFirmsCompletionList = async (keywords: string) => {
    try {
      const list = await getFirmsCompletionList(keywords);

      return list.data;
    } catch (error) {
      this.root.errors.setRetrievable({ name: 'getFirmsList', message: this.root.app.i18n.errors.getFirmsList });
    }
  };

  fetchGeoCompletionList = (prefix: string, locale: TUikitSupportedLocale) => {
    const localityGeoTypes = geoTypes.cities + geoTypes.countries + geoTypes.districts;

    return getGeoCompletionList(prefix, locale, localityGeoTypes)
      .then(resp => resp.data)
      .catch(() => {
        this.root.errors.setRetrievable({
          name: 'getGeoCompletionList',
          message: this.root.app.i18n.errors.getGeoCompletionList,
        });
      });
  };
}

export { Firms };
