import { AxiosRequestConfig } from 'axios';
import { SnakeCasedPropertiesDeep } from 'type-fest';

import { FilterType } from 'store/FilterStore/types';
import { camelToSnakeObj } from 'utils/objects';

import axios from '../axios';
import {
  ICreateFilterResponse,
  ISaveUserFilterRequest,
  IValidateFilterRequest,
  IValidateFilterResponse,
  TEditFilterRequest,
  TMobileDevicesResponse,
  TSmsParamsResponse,
  TSubscriptionRatesResponse,
  TSubscriptionTrialInfo,
} from './types';

export function validateFilter(data: SnakeCasedPropertiesDeep<IValidateFilterRequest>, options: AxiosRequestConfig) {
  return axios.post<IValidateFilterResponse>(`/gw/loads_filters/web/v1.0/loads/filters/validate`, data, options);
}

export function createFilter(data: ISaveUserFilterRequest) {
  return axios.post<ICreateFilterResponse>('/gw/loads_filters/web/v2/cargos/filters', camelToSnakeObj(data));
}

export function editFilter(id: string, data: TEditFilterRequest) {
  return axios.put<ICreateFilterResponse>(`/gw/loads_filters/web/v2/cargos/filters/${id}`, camelToSnakeObj(data));
}

export function getSmsParams() {
  return axios.get<TSmsParamsResponse>('/api/userfilters/GetSmsParameters');
}

export function getSubscriptionRates(data: FilterType, options: AxiosRequestConfig) {
  return axios.post<TSubscriptionRatesResponse>('/webapi/v1.0/loads/search/filters/subscription_rates', data, options);
}

export function getMobileDevices() {
  return axios.get<TMobileDevicesResponse>('/api/userfilters/GetFirmMobileDevices');
}

export function getSubscriptionsNotificationsInfo() {
  return axios.get<number>('/api/SubscriptionsNotificationsInfo');
}

// переименовать
export function deleteSubscription(id: string) {
  return axios.delete(`/webapi/v1.0/loads/subscriptions/${id}`);
}

export function createSubscription(filterId: string, data: any) {
  return axios.post(`/gw/loads_filters/web/v2/cargos/subscriptions/${filterId}`, data);
}

export function getSubscriptionTrialInfo() {
  return axios.get<SnakeCasedPropertiesDeep<TSubscriptionTrialInfo>>(
    '/webapi/v1.0/loads/subscriptions/trial_version_info',
  );
}
