import hash from 'object-hash';

import { FilterType } from 'store/FilterStore/types';

import { cleanEmpty } from './objects';

export function calculateFilterHash(filter: FilterType): string {
  if (filter?.firm?.firmId) {
    delete filter.firm.firmId;
  }

  const cleanedFilter = cleanEmpty(filter);

  return hash(cleanedFilter, { unorderedArrays: true });
}
