import { differenceInWeeks, isToday } from 'date-fns';
import { makeAutoObservable, reaction } from 'mobx';

import { getSubscriptionTrialInfo } from 'api/userFilters';
import { TSubscriptionTrialInfo } from 'api/userFilters/types';
import { geoSuggestionTypes } from 'constants/geo';
import { countryIds, geoTypes } from 'constants/routeParams';
import { BoardsStore } from 'store/FilterStore/BoardsStore';
import { Firms } from 'store/FilterStore/Firms';
import { Geo } from 'store/FilterStore/Geo';
import { LoadDates } from 'store/FilterStore/LoadDates';
import { ServerPropsIDs } from 'store/SiteFeatures/SiteFeatures';
import { BooleanField } from 'store/fileds/BooleanField';
import { IntegerField } from 'store/fileds/IntegerField';
import { MinMaxField } from 'store/fileds/MinMaxField';
import { SelectField } from 'store/fileds/SelectField';
import { calculateFilterHash } from 'utils/filterHash';
import { cleanEmpty, snakeToCamelObj } from 'utils/objects';
import {
  lastSearchedFilterStorage,
  subscriptionPromoCounterStorage,
  subscriptionPromoTimestampStorage,
} from 'utils/storage/Storage';

import { GeoInfomer } from '../FilterStore/GeoInformer';
import { RootStore } from '../RootStore';
import { CargoType } from './CargoType/CargoType';
import { ExtraParams } from './ExtraParams';
import { LoadingType } from './LoadingType';
import { Rate } from './Rate';
import { RouteParams } from './RouteParams';
import { TruckType } from './TruckType';
import { FilterType } from './types';

const trialDaysDuration = 7;

export enum AdditionalFilters {
  dates = 'dates',
  carTypes = 'carTypes',
  cargoTypes = 'cargoTypes',
  loadingTypes = 'loadingTypes',
  payment = 'payment',
  extraParams = 'extraParams',
  dimensions = 'dimensions',
  boards = 'boards',
  firms = 'firms',
}

class FilterStore {
  root: RootStore;
  routeParams: RouteParams;
  from: Geo;
  to: Geo;
  geoInfomer: GeoInfomer;
  loadDate: LoadDates;
  truckType: TruckType;
  cargoType: CargoType;
  loadingType: LoadingType;
  loadType;
  weight: MinMaxField;
  volume: MinMaxField;
  length: MinMaxField;
  width: MinMaxField;
  height: MinMaxField;
  withDimensions: BooleanField;
  pallets: IntegerField;
  extraParams: ExtraParams;
  rate: Rate;
  changeDate;
  sortingType;
  boards: BoardsStore;
  firms: Firms;
  truckId?: string | null = null;
  isSubmitted: boolean = false;
  isTruckInfoIncluded: boolean = false;

  isSubscriptionPromoVisible: boolean = false;
  isSubscriptionTrialInfoLoading: boolean = true;
  subscriptionTrialInfo: TSubscriptionTrialInfo | null;
  subscriptionPromoShownTimestamp: number = subscriptionPromoTimestampStorage.get();
  dailyPromoRefreshCounter: number = subscriptionPromoCounterStorage.get() || 0;
  lastSearchedFilterHash: string = lastSearchedFilterStorage.get();

  constructor(root: RootStore) {
    this.root = root;
    this.routeParams = new RouteParams(root);
    this.from = new Geo(this.root);
    this.to = new Geo(this.root);
    this.geoInfomer = new GeoInfomer(this.root);
    this.loadDate = new LoadDates(this.root);
    this.truckType = new TruckType(this.root);
    this.cargoType = new CargoType(this.root);
    this.loadingType = new LoadingType(this.root);
    this.weight = new MinMaxField(this.root, { unit: root.app.i18n.common.tons });
    this.volume = new MinMaxField(this.root, { unit: root.app.i18n.common.meter });

    this.length = new MinMaxField(this.root, { unit: root.app.i18n.common.meter });
    this.width = new MinMaxField(this.root, { unit: root.app.i18n.common.meter });
    this.height = new MinMaxField(this.root, { unit: root.app.i18n.common.meter });

    this.withDimensions = new BooleanField();

    this.pallets = new IntegerField();

    this.loadType = new SelectField(this.root.options.getLoadTypes);

    this.extraParams = new ExtraParams(this.root);
    this.rate = new Rate(this.root);

    this.changeDate = new SelectField(this.root.options.getChangeDate);

    this.sortingType = new SelectField(this.root.options.getSortingTypes);

    this.boards = new BoardsStore(this.root);

    this.firms = new Firms(this.root);

    this.subscriptionTrialInfo = null;

    makeAutoObservable(this);

    this.initHideSubscriptionPromoReactions();
  }

  get requestData(): FilterType {
    const filter = {
      ...this.routeParams.requestData,
      from: this.from.requestData,
      to: this.to.requestData,
      dates: this.loadDate.requestData,
      weight: this.weight.requestData,
      length: this.length.requestData,
      width: this.width.requestData,
      volume: this.volume.requestData,
      truckType: this.truckType.requestData,
      cargoTypes: this.cargoType.requestData,
      loadingType: this.loadingType.requestData,
      height: this.height.requestData,
      dogruz: this.loadType.requestData,
      withDimensions: this.withDimensions.requestData,
      pallets: this.pallets.data,
      ...this.extraParams.requestData,
      rate: this.rate.requestData,
      changeDate: this.changeDate.requestData,
      sortingType: this.sortingType.requestData,
      boardList: this.boards.requestData,
      withAuction: this.boards.withAuction.requestData,
      firm: this.firms.requestData,
      truckId: this.truckIdRequestData,
    };

    return cleanEmpty(filter);
  }

  get hash(): string {
    return calculateFilterHash(this.requestData);
  }

  get filledFiltersCount() {
    return Object.values(this.isFilterFilled).filter(Boolean).length;
  }

  get isFilterFilled() {
    return {
      dates: !this.loadDate.isDefault,
      carTypes: !this.truckType.isDefault,
      cargoTypes: !this.cargoType.isDefault,
      loadingTypes: !this.loadingType.isDefault,
      payment: !this.isPaymentDefault,
      extraParams: !this.isExtraParamsDefault,
      dimensions: !this.isDimensionsDefault,
      boards: !this.boards.isDefault,
      firms: !this.firms.isDefault,
      changeDate: !this.changeDate.isDefault,
    };
  }

  getSavedFilterByHash = () => {
    return this.root.tabsData?.savedFilters?.find(i => i.hash === this.hash);
  };

  get error() {
    return this.getError();
  }

  getError(options = { isVirtuallySubmitted: false }) {
    const isSubmitted = options.isVirtuallySubmitted || this.isSubmitted;

    const fromError = this.from.getError({ isVirtuallySubmitted: isSubmitted });
    const toError = this.to.getError({ isVirtuallySubmitted: isSubmitted });

    if (this.routeParams.isEllipseActive && (fromError || toError)) {
      return this.root.app.i18n.app.errors.invalidGeo;
    }

    if (fromError && toError) {
      if (this.isTruckTypeRequiresGeo) {
        return this.root.app.i18n.app.errors.truckTypeRequiresGeo;
      }

      return this.root.app.i18n.app.errors.invalidGeo;
    }

    if (isSubmitted && this.isOnlyRussiaSearch) {
      return this.root.app.i18n.app.errors.notOnlyRussia;
    }

    return null;
  }

  get isOnlyRussiaSearch() {
    const fromTo = [this.from.locality.suggestion, this.to.locality.suggestion].filter(Boolean);

    const isListSpecified = this.from.list?.id || this.to.list?.id;

    return (
      !isListSpecified &&
      Boolean(fromTo.length) &&
      fromTo.every(sug => sug?.type === geoSuggestionTypes.country && sug?.id === countryIds.russia)
    );
  }

  get localityGeoTypes() {
    if (this.routeParams.isEllipseActive) return geoTypes.cities;

    let localityGeoTypes = geoTypes.cities + geoTypes.countries + geoTypes.districts + geoTypes.lists;

    if (this.from.isCityLocality) {
      localityGeoTypes += geoTypes.directions;
    }

    return localityGeoTypes;
  }

  get isTruckTypeRequiresGeo() {
    return this.truckType.truckTypes.length > 0 && !this.truckType.hasOnlyRareTruckTypes;
  }

  get isDefault() {
    return (
      this.routeParams.isDefault &&
      this.from.isDefault &&
      this.to.isDefault &&
      this.loadDate.isDefault &&
      this.truckType.isDefault &&
      this.cargoType.isDefault &&
      this.loadingType.isDefault &&
      this.weight.isDefault &&
      this.volume.isDefault &&
      this.changeDate.isDefault &&
      this.sortingType.isDefault &&
      this.boards.isDefault &&
      this.firms.isDefault &&
      this.isDimensionsDefault &&
      this.isPaymentDefault &&
      this.isExtraParamsDefault
    );
  }

  get isDimensionsDefault() {
    return (
      this.loadType.isDefault &&
      this.withDimensions.isDefault &&
      this.length.isDefault &&
      this.width.isDefault &&
      this.height.isDefault &&
      this.pallets.isDefault
    );
  }

  get isPaymentDefault() {
    return this.rate.isDefault && !this.extraParams.hasPaymentType;
  }

  get isExtraParamsDefault() {
    return !this.extraParams.hasExtraParam;
  }

  get truckIdRequestData() {
    if (this.isTruckInfoIncluded) {
      return this.truckId;
    }

    return null;
  }

  handleGeoSwap = () => {
    const temp = this.to;
    this.to = this.from;
    this.from = temp;

    if (this.from.locality.suggestion?.type === -1) {
      this.from.locality.clear();
    }
  };

  setIsSubmitted = (isSubmitted: boolean) => {
    this.isSubmitted = isSubmitted;
  };

  setTruckId = (truckId: string | null) => {
    this.truckId = truckId;
  };

  resetTruckId = () => {
    this.setTruckId(null);
  };

  setIsTruckInfoIncluded = (isTruckInfoIncluded: boolean) => {
    this.isTruckInfoIncluded = isTruckInfoIncluded;
  };

  toggleIsTruckInfoIncluded = () => {
    this.setIsTruckInfoIncluded(!this.isTruckInfoIncluded);
  };

  resetDate = () => {
    this.loadDate = new LoadDates(this.root);
  };

  resetDimensions = () => {
    this.length = new MinMaxField(this.root, { unit: this.root.app.i18n.common.meter });
    this.width = new MinMaxField(this.root, { unit: this.root.app.i18n.common.meter });
    this.height = new MinMaxField(this.root, { unit: this.root.app.i18n.common.meter });

    this.withDimensions = new BooleanField();

    this.pallets = new IntegerField();
    this.loadType = new SelectField(this.root.options.getLoadTypes);
  };

  resetBoards = () => {
    this.boards = new BoardsStore(this.root);
  };

  resetFirms = () => {
    this.firms = new Firms(this.root);
  };

  resetAdditionalFilters = () => {
    this.resetDate();
    this.truckType.clearTruckTypes();
    this.cargoType.clearCargoTypes();
    this.loadingType.clearLoadingTypes();
    this.extraParams.resetPaymentParams();
    this.extraParams.resetExtraParams();
    this.resetDimensions();
    this.resetBoards();
    this.resetFirms();
    this.changeDate.reset();
  };

  // TODO: вынести в отдельный стор всё, что связаноо с рекламой подписок
  get trialDaysDuration() {
    return (
      this.root.siteFeatures.getServerProp(ServerPropsIDs.cargoSubscriptionsTrialDaysDuration)?.IntValue ||
      trialDaysDuration
    );
  }

  get isSubscriptionTrialInfoVisible() {
    return this.subscriptionTrialInfo?.trialStatus === 'not_started';
  }

  setIsSubscriptionPromoVisible = (value: boolean) => {
    this.isSubscriptionPromoVisible = value;
  };

  setSubscriptionPromoShownTimestamp = (timestamp: number) => {
    this.subscriptionPromoShownTimestamp = timestamp;
    subscriptionPromoTimestampStorage.set(timestamp);
  };

  setLastSearchedFilterHash = (hash: string) => {
    this.lastSearchedFilterHash = hash;
    lastSearchedFilterStorage.set(hash);
  };

  incrementDailyPromoRefreshCounter = () => {
    this.dailyPromoRefreshCounter += 1;
    subscriptionPromoCounterStorage.set(this.dailyPromoRefreshCounter);
  };

  resetDailyPromoRefreshCounter = () => {
    this.dailyPromoRefreshCounter = 0;
    subscriptionPromoCounterStorage.set(this.dailyPromoRefreshCounter);
  };

  hideSubscriptionPromo = () => {
    this.setIsSubscriptionPromoVisible(false);
    this.resetDailyPromoRefreshCounter();
  };

  initHideSubscriptionPromoReactions = () => {
    reaction(
      () => this.hash,
      () => {
        if (this.lastSearchedFilterHash !== this.hash) {
          this.hideSubscriptionPromo();
        }
      },
    );

    reaction(
      () => Boolean(this.getSavedFilterByHash()?.hasSubscription),
      () => this.hideSubscriptionPromo(),
    );
  };

  checkIfCanShowAnySubscriptionPromo = () => {
    const isSearchResultInLimit =
      this.root.app.searchResult &&
      this.root.app.searchResult?.totalItems >= 3 &&
      this.root.app.searchResult?.totalItems <= 500;

    const shouldShowAnySubscriptionPromo =
      this.root.profile.data?.isUser &&
      !this.root.profile.isFastReg &&
      !this.root.profile.hasUnlimitedNotificationsLicense &&
      !isToday(this.subscriptionPromoShownTimestamp) &&
      isSearchResultInLimit;

    return shouldShowAnySubscriptionPromo;
  };

  showSubscriptionPromo = () => {
    const canShowAnySubscriptionPromo = this.checkIfCanShowAnySubscriptionPromo();
    if (canShowAnySubscriptionPromo === false) return;

    const shouldShowWeeklyPromo = this.checkIfShouldShowWeeklySubscriptionPromo();
    if (shouldShowWeeklyPromo) {
      this.showWeeklySubscriptionPromo();
      return;
    }

    const shouldShowDailyPromo = this.checkIfShouldShowDailySubscriptionPromo();
    if (shouldShowDailyPromo) {
      this.showDailySubscriptionPromo();
      return;
    }
  };

  checkIfShouldShowWeeklySubscriptionPromo = () => {
    const isOneWeekHasPassed = differenceInWeeks(Date.now(), this.subscriptionPromoShownTimestamp) >= 1;
    const isSubscriptionPromoNeverShown = !this.subscriptionPromoShownTimestamp;
    const shouldShowWeeklyPromo =
      (isSubscriptionPromoNeverShown || isOneWeekHasPassed) && !this.root.subscriptions.hasSavedFilters;

    return shouldShowWeeklyPromo;
  };

  showWeeklySubscriptionPromo = async () => {
    this.setIsSubscriptionPromoVisible(true);
    this.setSubscriptionPromoShownTimestamp(Date.now());

    await this.fetchSubscriptionTrialInfo();
  };

  checkIfShouldShowDailySubscriptionPromo = () => {
    if (!this.root.subscriptions.hasSavedFilters || this.getSavedFilterByHash()?.hasSubscription) return;

    if (this.lastSearchedFilterHash !== this.hash) {
      this.setLastSearchedFilterHash(this.hash);
      this.resetDailyPromoRefreshCounter();
    }

    this.incrementDailyPromoRefreshCounter();

    const shouldShowDailyPromo = this.dailyPromoRefreshCounter >= 4;

    return shouldShowDailyPromo;
  };

  showDailySubscriptionPromo = async () => {
    this.setIsSubscriptionPromoVisible(true);
    this.setSubscriptionPromoShownTimestamp(Date.now());
    this.resetDailyPromoRefreshCounter();

    await this.fetchSubscriptionTrialInfo();
  };

  fetchSubscriptionTrialInfo = async () => {
    try {
      const { data } = await getSubscriptionTrialInfo();
      const formattedData = snakeToCamelObj(data);

      this.subscriptionTrialInfo = formattedData;
    } catch (error) {
      console.error(error);
    } finally {
      this.isSubscriptionTrialInfoLoading = false;
    }
  };
}

export { FilterStore };
