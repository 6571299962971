import { makeAutoObservable } from 'mobx';

import { uuidRegex } from 'constants/common';
import { extraParams as extraParamsValues } from 'constants/extraParams';
import { currenciesRateTypes } from 'constants/payment';
import { Geo } from 'store/FilterStore/Geo';
import { OldToSearch } from 'store/FilterStore/Mappers/OldToSearch';
import { FilterGeoType, FilterType } from 'store/FilterStore/types';
import { TTabIdsQueryMap } from 'store/UIStore/UIStore';
import { LocalityType } from 'types/cargosApp/Geo';
import { isEmpty } from 'utils/objects';

import { RootStore } from '../RootStore';

export type QueryParams = {
  tab?: keyof TTabIdsQueryMap;
};

const specifications = ['volume', 'weight', 'length', 'width', 'height'] as const;
export type Specifications = typeof specifications[number];

export enum QueryType {
  byFilter = 'byFilter',
  byLoadId = 'byLoadId',
}

type TQueryFilterType = Modify<
  FilterType,
  {
    boardList?: FilterType['boardList'] | 'onlyPersonal';
  }
>;

class QueryStore {
  root: RootStore;
  parsedQueryData: TQueryFilterType;
  type?: QueryType;
  loadId: string | null = null;

  constructor(root: RootStore) {
    this.root = root;
    this.parsedQueryData = {} as TQueryFilterType;

    makeAutoObservable(this);
  }

  get isEmpty() {
    return Object.keys(this.parsedQueryData).length === 0;
  }

  get queryParams(): URLSearchParams {
    if (typeof window === 'undefined') return new URLSearchParams('');

    const urlSearchParams = new URLSearchParams(window.location.search);

    return urlSearchParams;
  }

  get hashParams(): URLSearchParams {
    if (typeof window === 'undefined') return new URLSearchParams('');

    const urlSearchParams = new URLSearchParams(location.hash.substring(2));

    return urlSearchParams;
  }

  get queryData() {
    const { filter } = this.root;

    return filter.requestData;
  }

  setQuery = () => {
    /* Не триггерит событие hashchange */
    history.replaceState(
      null,
      '',
      `${document.location.pathname}${document.location.search}#?filter=${encodeURI(
        JSON.stringify(this.queryData),
      )}&version=v2`,
    );
  };

  clearQuery = () => {
    history.replaceState(null, '', `${document.location.pathname}${document.location.search}`);
  };

  parseQuery = () => {
    const loadId = this.hashParams.get('loadId');

    if (loadId && uuidRegex.test(loadId)) {
      this.setType(QueryType.byLoadId);
      this.setLoadId(loadId);
    } else {
      const version = this.hashParams.get('version');
      const filter = this.hashParams.get('filter');

      this.setType(QueryType.byFilter);

      if (filter) {
        const parsedFilter = JSON.parse(filter);
        const preparedFilter = version === 'v2' ? parsedFilter : OldToSearch(parsedFilter);

        try {
          this.setParsedQueryData(preparedFilter);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  setParsedQueryData = (data: TQueryFilterType) => {
    this.parsedQueryData = data;
  };

  setType = (type: QueryType) => {
    this.type = type;
  };

  setLoadId = (loadId: string | null) => {
    this.loadId = loadId;
  };

  get isFromFilter() {
    return this.type === QueryType.byFilter;
  }

  get isFromLoadId() {
    return this.type === QueryType.byLoadId;
  }

  fillFromQuery = async () => {
    this.root.app.setIsDataSetting(true);
    if (!isEmpty(this.parsedQueryData)) {
      this.root.clearFilter();

      this.fillRouteLengthFromQuery();
      this.fillEllipseFromQuery();
      await this.fillGeoFromQuery();
      this.fillSpecifications();
      this.fillDates();
      this.fillRate();
      this.fillAdditionalParams();
      this.fillSorting();
      this.fillBoardList();
      await this.fillFirms();
      this.fillTruckId();
    }
    // Через setTimeout, чтобы сначала отработали реакции, зависящие от того,
    // что мы при заполнении.
    setTimeout(() => {
      this.root.app.setIsDataSetting(false);
    }, 0);
  };

  fillRouteLengthFromQuery = () => {
    const { min, max } = this.parsedQueryData?.routeLength || {};

    if (min || max) {
      this.root.filter.routeParams.setIsRouteLengthActive(true);
      this.root.filter.routeParams.routeLength.setData({ min, max });
    }
  };

  fillEllipseFromQuery = () => {
    const { maxEnlargment, maxEnlargmentUnit, minLength, minLengthUnit } = this.parsedQueryData?.ellipse || {};

    if (this.parsedQueryData?.ellipse) {
      this.root.filter.routeParams.setIsEllipseActive(true);

      this.root.filter.routeParams.minLength.setValue(minLength || '');
      minLengthUnit && this.root.filter.routeParams.minLengthUnit.setData('value', minLengthUnit);

      this.root.filter.routeParams.maxEnlargment.setValue(maxEnlargment || '');
      maxEnlargmentUnit && this.root.filter.routeParams.maxEnlargmentUnit.setData('value', maxEnlargmentUnit);
    }
  };

  fillGeoFromQuery = async () => {
    const { from, to } = this.parsedQueryData;

    if (from) {
      const fromLocality = await this.extractGeoFromQuery(from);

      this.root.filter.from.setLocality(fromLocality);
      this.root.filter.from.setIsExact(from.exactOnly);
      from.radius && this.root.filter.from.radius.setValue(from.radius);
    }

    if (to) {
      const toLocality = await this.extractGeoFromQuery(to);
      this.root.filter.to.setLocality(toLocality);
      this.root.filter.to.setIsExact(to.exactOnly);
      to.radius && this.root.filter.to.radius.setValue(to.radius);
    }
  };

  fillSpecifications = () => {
    specifications.forEach((spec: Specifications) => {
      this.root.filter[spec]?.setData({
        min: this.parsedQueryData[spec]?.min,
        max: this.parsedQueryData[spec]?.max,
      });
    });
  };

  fillDates = () => {
    const { dateTo, dateFrom, dateOption } = this.parsedQueryData?.dates ?? {};

    if (dateOption) {
      this.root.filter.loadDate.setDateOption(dateOption);
    }

    if (dateOption === 'manual') {
      dateFrom && this.root.filter.loadDate.setFromDate(new Date(new Date(dateFrom).setHours(0, 0, 0, 0)));
      dateTo && this.root.filter.loadDate.setToDate(new Date(new Date(dateTo).setHours(0, 0, 0, 0)));
    }
  };

  fillAdditionalParams = () => {
    const { filter } = this.root;
    const { truckType, loadingType, cargoTypes, extraParams, excludeTenders, dogruz, withDimensions, pallets, adr } =
      this.parsedQueryData;

    truckType && filter.truckType.setTruckTypesFromBitSum(truckType);

    loadingType && filter.loadingType.setLoadingTypesFromBitSum(loadingType);

    cargoTypes?.length && filter.cargoType.setCargoTypes(cargoTypes.map(type => type.toString()));

    extraParams && filter.extraParams.setParamsFromBitSum(extraParams.toString());

    excludeTenders && filter.extraParams.addParam(extraParamsValues.tenders);

    adr &&
      filter.extraParams.ADR.setADR({
        type: 'include',
        ids: adr.ids ? adr.ids : undefined,
      });

    filter.loadType.setData('value', dogruz);

    withDimensions && filter.withDimensions.setData(withDimensions);

    pallets && filter.pallets.setValue(pallets);
  };

  fillRate = () => {
    const { filter } = this.root;
    const { currencyId, rateTotal, ratePerKm, ratePerHour } = this.parsedQueryData.rate ?? {};

    if (currencyId) {
      filter.rate.currency.setOptionByValue(currencyId);
      rateTotal && filter.rate.totalRate.setValue(rateTotal);
      ratePerKm && filter.rate.ratePerKm.setValue(ratePerKm);

      if (ratePerHour) {
        const currencyPerHour = filter.rate.getCurrencyByRateType(currencyId, currenciesRateTypes.perHour);

        filter.rate.totalRate.setValue(ratePerHour);
        currencyPerHour && filter.rate.totalRateCurrency.setOptionByValue(currencyPerHour.id);
      }
    }
  };

  fillSorting = () => {
    const { sortingType, changeDate } = this.parsedQueryData;

    sortingType && this.root.filter.sortingType.setData('value', sortingType);
    changeDate && this.root.filter.changeDate.setData('value', changeDate);
  };

  fillBoardList = () => {
    const { boardList, withAuction } = this.parsedQueryData;

    withAuction && this.root.filter.boards.withAuction.setData(withAuction);

    if (boardList === 'onlyPersonal') {
      this.root.filter.boards.selectOnlyPersonalBoards();

      return;
    }

    if (boardList?.length) {
      this.root.filter.boards.unSelectAllBoards();
      boardList.forEach((boardId: string) => {
        this.root.filter.boards.selectBoardById(boardId);
      });
    }
  };

  fillFirms = async () => {
    const firms = this.root.filter.firms;
    const { atiId, firmName, firmRating, firmGeo, firmListsExclusive, firmListsInclusive } =
      this.parsedQueryData.firm ?? {};

    if (firmRating) {
      const option = this.root.options.getRatingTypes().find(option => option.value === firmRating);
      option && firms.ratingType.setOption(option);
    }

    if (firmGeo?.id && firmGeo.type !== undefined) {
      const geoName = await Geo.getRestoredGeoNameById(firmGeo, this.root.dictionaries.geoLists);

      firms.firmGeo.onSuggestionSelected(undefined, {
        suggestion: { id: firmGeo.id, type: firmGeo.type, text: geoName, value: geoName },
      });
      firms.firmGeo.setValue(geoName);
    }

    if (atiId && firmName) {
      firms.firmName.onSuggestionSelected(undefined, { suggestion: { alias_id: atiId.toString() } });
      firms.firmName.setValue(firmName);
    }

    if (firmListsExclusive?.length) {
      firms.isExclusiveMode.setOptionByValue(true);
      firms.setSelectedList(firmListsExclusive);
    }

    if (firmListsInclusive?.length) {
      firms.setSelectedList(firmListsInclusive);
    }
  };

  fillTruckId = () => {
    const { truckId } = this.parsedQueryData;

    if (truckId) {
      this.root.filter.setTruckId(truckId);
      this.root.filter.setIsTruckInfoIncluded(true);
    }
  };

  extractGeoFromQuery = async (geo: FilterGeoType) => {
    const { id, type, listId } = geo;

    const geoName = await Geo.getRestoredGeoNameById(geo, this.root.dictionaries.geoLists);

    const suggestion: LocalityType = {
      text: geoName,
      city_name: '',
      attribute: 0,
      id: listId || String(id),
      type,
      value: geoName,
    };

    return suggestion;
  };
}

export { QueryStore };
