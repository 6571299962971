import format from 'date-fns/format';

import { TGeoSuggestionValues } from 'constants/geo';
import { Specifications } from 'store/QueryStore/QueryStore';
import { SavedFilter } from 'store/TabsDataStore/TabsDataStore';
import { capitalize } from 'utils/string/format';

import { IOldFilter, UserFilter } from '../old-types';
import { FilterGeoType, FilterType } from '../types';

const OldToSearch = (oldFilter: IOldFilter): FilterType => {
  return {
    ellipse: mapEllipse(oldFilter),
    routeLength: mapRouteLength(oldFilter),
    from: mapFromGeoPoint(oldFilter, 'from'),
    to: mapFromGeoPoint(oldFilter, 'to'),
    weight: mapRange(oldFilter, 'weight'),
    volume: mapRange(oldFilter, 'volume'),
    dates: mapDates(oldFilter),
    loadingType: mapLoadingType(oldFilter.loadingType),
    // @ts-ignore
    truckType: mapTruckType(oldFilter),
    cargoTypes: oldFilter.cargoTypes?.map(cargoType => Number(cargoType)),
    extraParams: oldFilter.extraParams,
    rate: mapRates(oldFilter),
    withDimensions: oldFilter.withDimensions,
    withAuction: oldFilter.withAuction,
    excludeTenders: oldFilter.excludeTenders,
    width: mapRange(oldFilter, 'width'),
    length: mapRange(oldFilter, 'length'),
    height: mapRange(oldFilter, 'height'),
    pallets: oldFilter.pallets || null,
    boardList: oldFilter.boardList,
    sortingType: oldFilter.sortingType,
    changeDate: oldFilter.changeDate,
    dogruz: mapDogruz(oldFilter),
    firm: mapFirms(oldFilter),
    truckId: oldFilter.truckId,
    adr: oldFilter.adr,
  };
};

const mapTruckType = (oldFilter: IOldFilter): number | string => {
  if (!oldFilter.truckType) return 0;

  const truckType = oldFilter.truckType.toString();

  if (oldFilter.truckType === '0') {
    return 0;
  }

  return truckType;
};

const mapFromGeoPoint = (oldFilter: IOldFilter, point: 'to' | 'from'): FilterGeoType | null => {
  if (!oldFilter[`${point}Geo`] && !oldFilter[`${point}List`]) return null;

  const capPoint = capitalize(point) as 'To' | 'From';

  // TODO:
  // косяк, который нужно править на серверной стороне
  // если выбран эллипс, то галочки Только по Загрузке/Разгрузке
  // должны быть всегда true, сейчас от метода userfilters/tabs приходят false :(
  const isExactOnly = oldFilter.ellipse?.enabled ? true : oldFilter[`exact${capPoint}Geos`];
  const radiusValue = oldFilter[`${point}Radius`];
  const geo = {
    exactOnly: isExactOnly,
    radius: radiusValue || undefined,
  };

  const standardGeo = oldFilter[`${point}Geo`];
  const listGeo = oldFilter[`${point}List`];

  if (listGeo) {
    return {
      ...geo,
      listType: listGeo?.type,
      listId: listGeo?.id,
      type: 5,
    };
  }

  if (standardGeo) {
    const [type, id] = standardGeo.split('_');

    return { ...geo, type: Number(type) as TGeoSuggestionValues, id: Number(id) };
  }

  return { ...geo, type: 0 };
};

const mapEllipse = (oldFilter: IOldFilter): TEllipse | undefined => {
  if (!oldFilter?.ellipse?.enabled) return undefined;
  const {
    ellipse: { maxEnlargment, maxEnlargmentUnit, minLength, minLengthUnit },
  } = oldFilter;
  return { maxEnlargment, maxEnlargmentUnit, minLength, minLengthUnit };
};

const mapRange = (oldFilter: IOldFilter, propName: Specifications) => {
  const prop = oldFilter[propName];

  if (prop) {
    return {
      min: prop.from || null,
      max: prop.to || null,
    };
  }
  return null;
};

const mapRouteLength = (oldFilter: IOldFilter) => {
  const { enabled, min, max } = oldFilter.routeParams || {};

  if (!enabled) {
    return null;
  }

  return {
    min: min || null,
    max: max || null,
  };
};

const mapDates = (oldFilter: IOldFilter): TLoadDates => {
  const { dateFrom, dateTo, dateOption } = oldFilter;

  if (dateOption === 'manual') {
    return {
      dateOption,
      dateFrom: dateFrom ? format(new Date(dateFrom), 'yyyy-MM-dd') : null,
      dateTo: dateTo ? format(new Date(dateTo), 'yyyy-MM-dd') : null,
    };
  }

  return {
    dateOption,
    dateFrom: null,
    dateTo: null,
  };
};

const mapLoadingType = (loadingType: number): string => {
  // @ts-ignores
  return loadingType > 0 ? loadingType.toString() : loadingType;
};

const mapRates = (oldFilter: IOldFilter): TRate | null => {
  if (!oldFilter.currencyId) return null;

  return {
    currencyId: oldFilter.currencyId,
    rateTotal: oldFilter.rateTotal,
    ratePerKm: oldFilter.ratePerKm,
    ratePerHour: oldFilter.ratePerHour,
  };
};

const mapDogruz = (oldFilter: IOldFilter) => {
  const dorguzMap = {
    0: null,
    1: true,
    2: false,
  };

  return dorguzMap[oldFilter.dogruz];
};

const mapFirms = (oldFilter: IOldFilter): TFirms => {
  const firms = {
    firmListsExclusive: oldFilter.firmListsExclusive,
    firmListsInclusive: oldFilter.firmListsInclusive,
    firmRating: oldFilter.firmRating || null,
    ...(oldFilter.firmId && { atiId: oldFilter.firmId?.toString(), firmName: oldFilter.firmName }),
    ...(oldFilter.firmGeoIds && {
      firmGeo: {
        type: Number(oldFilter.firmGeoIds.split('_')[0]) as TGeoSuggestionValues,
        id: oldFilter.firmGeoIds.split('_')[1],
      },
    }),
  };

  return firms;
};

const mapUserFilterToSavedFilter = (userFilter: UserFilter): SavedFilter => ({
  id: userFilter.id,
  boardsAvailability: userFilter.boardsAvailabilityFlag,
  isCollective: userFilter.isCollective,
  name: userFilter.name,
  searchDate: userFilter.searchDate,
  departmentId: userFilter.departmentId,
  filter: OldToSearch(userFilter.filter),
});

export { OldToSearch, mapUserFilterToSavedFilter };
