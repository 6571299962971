import { makeAutoObservable, reaction } from 'mobx';

import { getMobileDevices } from 'api/userFilters';
import { TMobileDevicesResponse } from 'api/userFilters/types';
import { RootStore } from 'store/RootStore';

// Тайминг такой же как в старом поиске;
const pollingTimeoutInterval = 1000 * 60 * 3;

export class MobileDevices {
  root: RootStore;
  availableDevices: TMobileDevicesResponse | null = null;
  pollingTimeout: ReturnType<typeof setTimeout> | null;

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this);

    this.pollingTimeout = null;

    reaction(
      () => this.root.savedFilters.popup,
      () => {
        if (this.root.savedFilters.popup) {
          setTimeout(this.pollMobileDevices, pollingTimeoutInterval);
        } else {
          this.pollingTimeout && clearTimeout(this.pollingTimeout);
        }
      },
    );
  }

  get hasMobileAppInstalled() {
    const availableDevicesForContact = this.availableDevices?.availableChannels.filter(
      channel => channel.contactId === this.root.profile.contact?.id,
    );

    return Boolean(availableDevicesForContact?.length);
  }

  setData = (availableDevices: TMobileDevicesResponse) => {
    this.availableDevices = availableDevices;
  };

  fetchMobileDevices = async () => {
    try {
      const { data } = await getMobileDevices();
      this.setData(data);
    } catch (error) {
      this.root.errors.setRetrievable({
        name: 'getMobileDevices',
        message: this.root.app.i18n.errors.getMobileDevices,
      });
    }
  };

  pollMobileDevices = async () => {
    if (!this.root.profile.data?.isUser || !this.root.savedFilters.popup) return;

    await this.fetchMobileDevices();

    this.pollingTimeout = setTimeout(this.pollMobileDevices, pollingTimeoutInterval);
  };
}
