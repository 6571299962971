import { makeAutoObservable } from 'mobx';

import { getLoads, getLoadsByIds, hideLoads, showLoads } from 'api/search';
import type { LotType, SearchRequestType, SearchResultType } from 'api/search/types';
import { statusCode } from 'constants/result';
import { searchScrollAnchorId, staticTabsId } from 'constants/ui';
import { TI18n, TSupportedLocales, TUikitSupportedLocale } from 'localization/types';
import { mapUserFilterToSavedFilter } from 'store/FilterStore/Mappers/OldToSearch';
import { QueryType } from 'store/QueryStore/QueryStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';
import { SelectedLoads } from 'store/UIStore/SelectedLoads';
import { LoadType } from 'types/cargosApp/Load';
import { getInitialData } from 'utils/getInitialData';
import { sendDataLakeEvent } from 'utils/metrics';
import { scrollTo } from 'utils/nativeDOM';
import { camelToSnakeObj, cleanEmpty } from 'utils/objects';

import { RootStore } from '../RootStore';
import { PopularDestinations } from './PopularDestinations';

const initialLoadingState = {
  search: false,
  initial: false,
  deleteSavedFilter: false,
  deleteLastSearchRequest: false,
  tabsData: false,
  deleteSubscription: false,
  hideLoads: false,
  showHiddenLoads: false,
};

interface SearchLoads {
  isNewSearch?: boolean;
  skipScroll?: boolean;
}

class AppStore {
  locale: TSupportedLocales = getInitialData('locale', 'ru');
  host = '';
  buildEnv = getInitialData('buildEnv', '');
  i18n;
  root: RootStore;
  searchResult: SearchResultType | null = null;
  loading = initialLoadingState;
  isDataSetting = false;
  popularDestinations: PopularDestinations;
  currentResultFilter: typeof this.root.filter.requestData | null = null;
  selectedLoads: SelectedLoads;
  hiddenLoadsIncrement: number = 0;
  serverDate: Date | null = null;

  constructor(root: RootStore, i18n: TI18n) {
    this.root = root;
    this.popularDestinations = new PopularDestinations(root, i18n);
    this.currentResultFilter = null;
    this.selectedLoads = new SelectedLoads();

    this.i18n = i18n;

    makeAutoObservable(this);
  }

  setIsDataSetting = (value: boolean) => {
    this.isDataSetting = value;
  };

  setServerDate = (date: Date) => {
    this.serverDate = date;
  };

  get env() {
    return this.host.split('.')[1];
  }

  // Временно, пока компоненты не поддержат остальные локали.
  get uiComponentsLocale(): TUikitSupportedLocale {
    const uikitSupportedLocales = ['ru', 'en'];
    if (uikitSupportedLocales.includes(this.locale)) return this.locale as Extract<TSupportedLocales, 'ru' | 'en'>;

    return 'ru';
  }

  get hiddenLoadsCount() {
    if (!this.searchResult) return 0;

    return this.searchResult.hiddenItems + this.hiddenLoadsIncrement;
  }

  get hasLoads() {
    return Boolean(this.loads.length);
  }

  get loads(): (LoadType | LotType)[] {
    const loads = this.searchResult?.loads || [];
    const lots = this.searchResult?.lots;
    const isLotsFeatureEnabled = this.root.siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.lots];

    const updateSelected = (loadsToSelect: (LoadType | LotType)[]) => {
      return loadsToSelect.map(load => ({
        ...load,
        checked: this.selectedLoads.ids.includes(load.id),
      }));
    };

    if (lots && isLotsFeatureEnabled) {
      const loadsWithLots = this.mergeLotsWithLoads(loads, lots);
      const withSelectedAndLots = updateSelected(loadsWithLots);

      return withSelectedAndLots;
    }

    const loadsWithSelected = updateSelected(loads);

    return loadsWithSelected;
  }

  updateLoad = (updatedLoad: LoadType) => {
    const indexToUpdate = this.searchResult?.loads.findIndex(load => load.id == updatedLoad.id);

    if (this.searchResult && typeof indexToUpdate === 'number') {
      this.searchResult.loads[indexToUpdate] = updatedLoad;
    }
  };

  initialize = async (initialData: any) => {
    this.setLoading('initial', true);
    this.setLocale(initialData.locale);
    this.setHost(initialData.host);

    await Promise.allSettled([this.root.dictionaries.fetchDictionaries(), this.root.siteFeatures.fetchSiteFeatures()]);

    const resources = [];
    const isUserLoggedIn = this.root.profile.data?.isUser;
    if (isUserLoggedIn) {
      resources.push(this.root.siteFeatures.fetchServerProps());
      resources.push(this.root.enablerFeaturesRepo.fetchFeatures());
      resources.push(this.root.filter.boards.fetchBoards());
      resources.push(this.root.followedLoads.favorites.fetchFavorites());
      resources.push(this.root.followedLoads.loadsWithOffers.fetchLoadsWithOffers());
      resources.push(this.root.followedLoads.loadsWithAuctions.fetchLoadsWithAuctions());
      resources.push(this.root.tabsData.fetchTabsData());
    }

    await Promise.allSettled(resources);

    this.setLoading('initial', false);

    await this.fillFilterFromQuery();
    this.root.ui.setTabFromQuery();
  };

  fillFilterFromQuery = async (options = { skipQueryParsing: false }) => {
    !options.skipQueryParsing && this.root.query.parseQuery();

    try {
      if (this.root.query.isFromLoadId && this.root.query.loadId) {
        this.searchLoadFromLoadId(this.root.query.loadId);
      } else {
        await this.root.query.fillFromQuery();

        if (!this.root.query.isEmpty) {
          this.searchLoads();

          if (this.root.query.hashParams?.has('subscribe') && this.root.profile.data?.isUser) {
            this.root.savedFilters.openFilterPopup(undefined, { enableSubscription: true });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  setLocale = (locale: TSupportedLocales) => {
    this.locale = locale;
  };

  setHost = (host: string) => {
    this.host = host;
  };

  getRequestData = (useCurrentResultFilter: boolean) => {
    const requestData: SearchRequestType = {
      excludeGeoDicts: true,
      page: this.root.ui.pagination.currentPage,
      itemsPerPage: this.root.ui.pagination.loadsPerPage.data,
      filter:
        useCurrentResultFilter && this.currentResultFilter ? this.currentResultFilter : this.root.filter.requestData,
    };

    return requestData;
  };

  searchLoads = async (options: SearchLoads = { isNewSearch: true, skipScroll: false }) => {
    sendDataLakeEvent('SearchLoads');

    const { isNewSearch, skipScroll } = options;

    const useCurrentResultFilter = Boolean(this.loads.length && !isNewSearch);

    if (isNewSearch) {
      this.root.ui.pagination.setCurrentPage(1);
    }

    if (!this.root.filter.isTruckInfoIncluded) {
      this.root.filter.resetTruckId();
    }

    const requestData = this.getRequestData(useCurrentResultFilter);

    this.root.filter.setIsSubmitted(true);

    if (!useCurrentResultFilter && this.root.filter.error) {
      scrollTo(`#${staticTabsId}`, { block: 'start' });
      return;
    }

    this.setLoading('search', true);

    try {
      const response = await getLoads(camelToSnakeObj<SearchRequestType>(cleanEmpty(requestData)));

      this.root.app.setServerDate(response.headers.date as unknown as Date);

      const { data } = response;

      this.setLoads(data);

      this.selectedLoads.unselectAll();
      this.resetHiddenLoadsIncrement();

      if (this.searchResult?.userFilter) {
        this.root.tabsData.appendLastSearch(mapUserFilterToSavedFilter(this.searchResult.userFilter));
      }

      if (!skipScroll) {
        scrollTo(`#${searchScrollAnchorId}`, { block: 'start' });
      }

      if (isNewSearch) {
        this.popularDestinations.fetchData();
        this.currentResultFilter = this.root.filter.requestData;
      }

      const isTooManyLoads = data?.statusCode === statusCode.tooManyLoads;
      const errorMessage = data?.errorMessage ? data.errorMessage : this.root.app.i18n.errors.tooManyLoads;

      if (isTooManyLoads) {
        this.root.errors.setRetrievable({ name: 'isTooManyLoads', message: errorMessage });
      }

      if (data?.loadBoardsNotAvailable) {
        this.root.errors.setRetrievable({
          name: 'loadBoardsNotAvailable',
          message: this.root.app.i18n.errors.loadBoardsNotAvailable,
        });
      }

      if (isNewSearch) {
        this.root.query.setQuery();
      }

      this.root.filter.showSubscriptionPromo();
    } catch (error) {
      console.error(error);

      this.root.errors.setRetrievable({ name: 'getLoads', message: this.root.app.i18n.errors.getLoads });
    } finally {
      this.setLoading('search', false);
      this.root.query.setLoadId(null);
    }
  };

  searchLoadFromLoadId = async (loadId: string) => {
    if (!this.root.profile.data?.isUser) {
      this.root.query.clearQuery();
      return;
    }

    this.setLoading('search', true);

    try {
      const { data } = await getLoadsByIds([loadId]);

      this.setLoads(data);

      this.resetHiddenLoadsIncrement();

      if (data?.loadBoardsNotAvailable) {
        this.root.errors.setRetrievable({
          name: 'loadBoardsNotAvailable',
          message: this.root.app.i18n.errors.loadBoardsNotAvailable,
        });
      }
    } catch (error) {
      console.error(error);

      this.root.errors.setRetrievable({ name: 'getLoads', message: this.root.app.i18n.errors.getLoads });
    } finally {
      this.setLoading('search', false);
      this.root.query.clearQuery();
      this.root.clearFilter();
    }
  };

  onLoadsHide = ({ displayableLoads, hiddenLoadsIds }: { displayableLoads: LoadType[]; hiddenLoadsIds: string[] }) => {
    if (!this.searchResult) return;

    this.setHiddenLoadsIncrement(hiddenLoadsIds.length);

    if (displayableLoads.length > 0) {
      this.setLoads({
        ...this.searchResult,
        loads: displayableLoads,
      });
    } else {
      this.handleHideAllLoadsOnPage();
    }

    this.selectedLoads.unselect(hiddenLoadsIds);
  };

  setHiddenLoadsIncrement = (increment: number) => {
    this.hiddenLoadsIncrement += increment;
  };

  resetHiddenLoadsIncrement = () => {
    this.hiddenLoadsIncrement = 0;
  };

  handleHideAllLoadsOnPage = () => {
    const isLastPage = this.root.ui.pagination.isLastSearchPage;
    if (isLastPage && this.root.ui.pagination.currentPage !== 1) {
      this.root.ui.pagination.setCurrentPage(this.root.ui.pagination.currentPage - 1);
    }

    if (this.searchResult?.totalItems === 1) {
      scrollTo(`#${staticTabsId}`, { block: 'start' });
      return;
    }

    this.searchLoads({ isNewSearch: false, skipScroll: !isLastPage });
  };

  hideSelectedLoads = async () => {
    try {
      this.setLoading('hideLoads', true);
      const { actuallyHidden, visuallyHidden } = this.getLoadsIdsToHide();
      await hideLoads(actuallyHidden);

      const displayableLoads = this.loads.filter(load => !this.selectedLoads.ids.includes(load.id));
      this.onLoadsHide({ displayableLoads, hiddenLoadsIds: visuallyHidden });
    } catch (error) {
      this.root.errors.setRetrievable({ name: 'hideLoads', message: this.root.app.i18n.errors.hideLoads });
    } finally {
      this.setLoading('hideLoads', false);
    }
  };

  showHiddenLoads = async () => {
    try {
      this.setLoading('showHiddenLoads', true);

      await showLoads();

      if (this.root.query.type === QueryType.byLoadId && this.root.query.loadId) {
        this.searchLoadFromLoadId(this.root.query.loadId);
      } else {
        this.searchLoads({ isNewSearch: false });
      }

      this.selectedLoads.unselectAll();
    } catch (error) {
      this.root.errors.setRetrievable({ name: 'hideLoads', message: this.root.app.i18n.errors.hideLoads });
    } finally {
      this.setLoading('showHiddenLoads', false);
    }
  };

  setLoads = (result: SearchResultType) => {
    this.searchResult = result;
  };

  setLoading = (stateName: keyof typeof initialLoadingState, value: boolean) => {
    this.loading[stateName] = value;
  };

  private mergeLotsWithLoads = (loads: LoadType[], lots: LotType[]): (LoadType | LotType)[] => {
    const sumRates = (lot: LotType, type: 'price' | 'priceNds' | 'priceNoNds') => {
      return lot.cargos.reduce((acc: number, current: LoadType) => {
        if (current.rate) return acc + (current.rate[type] || 0);

        return 0;
      }, 0);
    };

    const sortByFirstLoadFromSearch = (loadFromSearch: LoadType, cargosFromLot: LoadType[]) => {
      return cargosFromLot.slice().sort(({ id }) => (loadFromSearch.id === id ? -1 : 1));
    };

    const replaceLoadByLot = (load: LoadType) => {
      const isLoadFromLot = load.lotId;

      if (isLoadFromLot) {
        const lot = lots.find(lot => lot.id === load.lotId);

        if (lot) {
          const updatedLot = {} as LotType;

          updatedLot.isLot = true;
          updatedLot.lotNumber = load.lotNumber;
          updatedLot.firm = load.firm;
          updatedLot.cargos = sortByFirstLoadFromSearch(load, lot.cargos);
          updatedLot.rate = {
            ...load.rate,
            price: sumRates(lot, 'price'),
            priceNds: sumRates(lot, 'priceNds'),
            priceNoNds: sumRates(lot, 'priceNoNds'),
          };

          const firstCargoInLot = lot.cargos[0];
          updatedLot.loading = firstCargoInLot.loading;
          updatedLot.unloading = firstCargoInLot.unloading;
          updatedLot.boards = firstCargoInLot.boards;

          // чтобы хоть какая-то кнопка отобрдажалась, пока берём данные из первого лота, хотя они могут быть разные
          // данные будут использоваться только для показа кнопки покупки лицензии
          // дальше надо будет предлагать правильную лицензию (либо международнюю, либо по россии, либо все грузы),
          // в зависимости от той, которой не будет у пользователя
          updatedLot.route = firstCargoInLot.route;

          return {
            ...lot,
            ...updatedLot,
          };
        }
      }

      return load;
    };

    const loadsWithFirstUniqueLoadFromLot = [...new Map(loads.map(load => [load.lotId || load.id, load])).values()];
    const loadsWithLots = loadsWithFirstUniqueLoadFromLot.map(replaceLoadByLot);

    return loadsWithLots;
  };

  private getLoadsIdsToHide = () => {
    const selectedLots = this.loads.filter(load => {
      if ('isLot' in load) {
        return this.selectedLoads.ids.includes(load.id);
      }

      return false;
    }) as LotType[];
    const selectedLotsIds = selectedLots.map(({ id }) => id);
    const selectedLoadsIds = this.selectedLoads.ids.filter(id => !selectedLotsIds.includes(id));
    const cargosIdsFromSelectedLots = selectedLots.flatMap(({ cargos }) => cargos.map(({ id }) => id));

    return {
      actuallyHidden: [...selectedLoadsIds, ...cargosIdsFromSelectedLots],
      visuallyHidden: [...selectedLoadsIds, ...selectedLotsIds],
    };
  };
}

export { AppStore };
