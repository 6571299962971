import { ChangeDate } from 'constants/changeDate';

import { EnumChangeDate, FilterType } from '../types';

const mapChangeDate = (
  searchFilter: Modify<FilterType, { changeDate: 'today' | 'all' | 'hour' | 'three-hours' | 'three-days' | 'week' }>,
): EnumChangeDate => {
  const changeDateMap = {
    all: ChangeDate.any,
    hour: ChangeDate.hours1,
    'three-hours': ChangeDate.hours3,
    today: ChangeDate.today,
    'three-days': ChangeDate.days3,
    week: ChangeDate.week,
  } as { [key: string]: EnumChangeDate };

  if (typeof searchFilter.changeDate === 'string') {
    return changeDateMap[searchFilter.changeDate];
  }

  return searchFilter.changeDate;
};

export { mapChangeDate };
